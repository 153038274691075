import React, { useState, useEffect } from "react";
import { Button } from "components/Button";
import { useNavigate } from "react-router-dom";
import { NotifyModal } from "components/NotifyModal";
import home_example from "assets/images/home_example.png";
import "./style.css";

export const Home = (): JSX.Element => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [printCnt, setPrintCnt] = useState<Number>(0);
  const [makeCnt, setMakeCnt] = useState<Number>(0);
  const closeModal = (): void => {
    setIsModalOpen(false);
  };
  const navigate = useNavigate(); // navigate 함수 생성
  const handleStartClick = () => {
    navigate("/select");
  };
  const handleHelpClick = () => {
    setIsModalOpen(true);
  };

  const loadingdata = async () => {
    try {
      // 서버 주소는 본인의 환경에 맞게 수정하세요.
      var response = await fetch("https://socphoto.shop/api/print");
      var data1 = await response.json();
      setPrintCnt(data1.printRequestCount);
      console.log("shareRequestCount:", data1.printRequestCount);
      response = await fetch("https://socphoto.shop/api/share");
      var data2 = await response.json();
      setMakeCnt(data1.printRequestCount + data2.printRequestCount);
      console.log("printRequestCount:", data2.printRequestCount);
    } catch (error) {
      console.error("GET 요청 중 오류가 발생했습니다:", error);
    }
  };

  useEffect(() => {
    loadingdata();
  }, []);

  return (
    <div className="home">
      <div className="div">
        <Button
          className="button-1"
          text="Start"
          onClick={handleStartClick}
        />
        <Button className="button-2" text="Letter" onClick={handleHelpClick} />
        <h1 className="h-1">
          Welcome
          <br />
          TO 4CUT!
        </h1>
        <img className="element" alt="Element" src={home_example} />
        <NotifyModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          message={
            "Hello bubu! It's hard to believe that almost two years have passed since we first met. Looking back, I can say with certainty that choosing you was the proudest decision of my life. I knew you were the one from the start, and I've never once regretted that choice. Only joy and pride. I've put a little effort into creating this app because I know how much you love these 4-cut photos, and I wanted to bring a piece of that joy to you since it's not available in Brazil. I hope you find some joy to this :) Before ending the letter, I want to take this moment to remind you just how special you are to me. You're beautiful in every way imaginable. You are exactly what I want, and I wouldn't change a thing about you. I chose you, and I want to be with you until the very end. This day has become one of the most significant in my life, and I'm deeply thankful for it. I also hope that you realize that if you could look in the mirror and see what I see when I look at you, you'd never doubt yourself again. Thank you for being Trinity and thank Trinity for being you. Thank you for being such an integral part of my life. Happy birthday, bubu! I can't wait to see you again and continue our journey together."
          }
          sign={"- Minjae"}
        />
      </div>
    </div>
  );
};
